export default {
  namespaced: true,
  state: {
    datos_adjuntos: [],
    formularios: [],

    carpetas: [],
    archivo_seleccionado: {},
    coleccion_archivo_selecionado: "",
    carpeta_seleccionada: {
      nombre_carpeta: "",
    },
    archivos_carpeta_seleccionada: [],
  },
  mutations: {},
  actions: {
    async GET_ARCHIVOS_CARPETA_SELECCIONADA({ dispatch, state }, carpeta) {
      try {
        console.log(carpeta);
        const id_carpeta = carpeta.id;

        const get_archivos_carpeta = await dispatch(
          "llamado_get",
          {
            url: `https://docutest.educandote.co/types/collections/IMAGENES_${
              state.coleccion_archivo_seleccionado
            }/documents/search?q=${id_carpeta}&query_by=${"id_carpeta"}&filter_by=estado_imagen:[habilitada]&per_page=${250}&sort_by=fecha_creacion_int:asc`,
            tipo_header: "indexador",
          },
          { root: true }
        );

        console.log(get_archivos_carpeta);

        let archivos = await dispatch(
          "utils/SET_DATA_INDEXADOR",
          get_archivos_carpeta.data.hits,
          { root: true }
        );

        state.archivos_carpeta_seleccionada = [...archivos];

        console.log(state.archivos_carpeta_seleccionada);

        if (get_archivos_carpeta.data.found > 250) {
          let cantidad_paginas = parseInt(get_archivos_carpeta / 250) + 1;

          for (let index = 2; index <= cantidad_paginas; index++) {
            let archivos_carpeta = await dispatch("llamado_get", {
              url: `https://docutest.educandote.co/types/collections/IMAGENES_${
                state.coleccion_archivo_seleccionado
              }/documents/search?q=${id_carpeta}&query_by=${"id_carpeta"}&per_page=${index}`,
              tipo_header: "indexador",
            });

            console.log(archivos_carpeta);

            archivos = await dispatch(
              "utils/SET_DATA_INDEXADOR",
              archivos_carpeta.data.hits,
              { root: true }
            );

            console.log(archivos);

            state.archivos_carpeta_seleccionada = [
              ...state.archivos_carpeta_seleccionada,
              ...archivos,
            ];

            //Insert files in folder.
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GET_CARPETAS({ dispatch, state }) {
      try {
        const get_carpetas = await dispatch(
          "llamado_get",
          {
            url: `https://docutest.educandote.co/types/collections/CARPETAS_${
              state.coleccion_archivo_seleccionado
            }/documents/search?q=${
              state.archivo_seleccionado.id
            }&query_by=${"pertenece_a"}&per_page=${250}`,
            tipo_header: "indexador",
          },
          { root: true }
        );

        console.log(get_carpetas);

        state.carpetas = await dispatch(
          "utils/SET_DATA_INDEXADOR",
          get_carpetas.data.hits,
          { root: true }
        );

        console.log(state.carpetas);
      } catch (error) {
        console.log(error);
      }
    },
    async CREAR_CARPETA({ dispatch, state }, payload) {
      console.log(payload);
      try {
        const carpeta = await dispatch(
          "llamado_post",
          {
            url: `https://docutest.educandote.co/types/collections/CARPETAS_${state.coleccion_archivo_seleccionado}/documents`,
            body: {
              nombre_carpeta: payload.nombre_carpeta,
              id_carpeta: state.carpetas.length + 1,
              pertenece_a: state.archivo_seleccionado.id.toString(),
              estado_carpeta: "ACTIVO",
              fecha_creacion_int: Date.parse(new Date()),
              order: Date.parse(new Date()),
              extended_properties: JSON.stringify({}),
            },
            tipo_header: "indexador",
          },
          { root: true }
        );

        console.log(carpeta);

        //agregar a state.carpetas
      } catch (error) {
        console.log(error);
        return false;
      }
      return true;
    },
    async EDITAR_CARPETA({ dispatch, state }, payload) {
      try {
        const carpeta = await dispatch(
          "llamado_patch",
          {
            url: `https://docutest.educandote.co/types/collections/CARPETAS_${state.coleccion_archivo_seleccionado}/documents/${payload.id}`,
            body: {
              nombre_carpeta: payload.nombre_carpeta,
            },
            tipo_header: "indexador",
          },
          { root: true }
        );

        console.log(carpeta);
      } catch (error) {
        console.log(error);
        return false;
      }
      return true;
    },
    async ELIMINAR_CARPETA({ dispatch, state }, payload) {
      try {
        const carpeta = await dispatch(
          "llamado_patch",
          {
            url: `https://docutest.educandote.co/types/collections/CARPETAS_${state.coleccion_archivo_seleccionado}/documents/${payload.id}`,
            body: {
              estado_carpeta: "INACTIVA",
            },
            tipo_header: "indexador",
          },
          { root: true }
        );

        console.log(carpeta);
      } catch (error) {
        console.log(error);
        return false;
      }
      return true;
    },
    async AGREGAR_ARCHIVOS_CARPETA({ dispatch, state }, payload) {
      try {
        console.log(dispatch);
        console.log(state);
        console.log(payload);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {},
};
