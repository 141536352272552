import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import router from '../router'
import documentos from './modules/documentos'
import formularios from './modules/formularios'
import dataUser from './modules/dataUser'
import admin from './modules/admin'
import apis from './modules/apis'
import workflows from './modules/workflows'
import buscador from './modules/buscador'
import utils from './modules/utils'

export default new Vuex.Store({

    modules: {
        documentos,
        formularios,
        dataUser,
        admin,
        apis,
        workflows,
        buscador,
        utils,
    },
    state: {
        usuario: 'fry',
        sheet: false,
        mensaje: '',

        dialogError: false,
        dialogExitoso: false,
        dialogAlerta: false, //Variable creada por fry
        dialogCargando: false,
        //PRUEBA
        meta_data_store: [],



        mostrarDatos: false,
        mostrarDatosRespuesta: false,

        //----------EDUAR--------------
        mostrarDocumentosEnviados: false,
        mostrarDocumentosRecividos: true,
        mostrarCasos: true,
        //------------------------------


        //-----Eduar
        archivos_seleccionados: [],
        titulo_bandeja_entrada: '',
        es_rechazado: false,
        dialogo_respuesta_caso_hijo: false,
        tipo_para_arreglar_problas_de_ruta: '',

        header: '',
        imgPdf: [],
        token: '',
        idUsuario: null,
        identificacion: null,

        load: false,
        load_linear: false
    },
    mutations: {
        agregar_archivo(state, archivo) {
            console.log(archivo)
            let varAux = state.archivos_seleccionados.filter(
                (item) => item.token == archivo.token
            );
            console.log(varAux);
            console.log(varAux[0]);
            var i = state.archivos_seleccionados.indexOf(varAux[0]);
            if (i != -1) {
                console.log('Ya esta')
            }
            else {
                state.archivos_seleccionados.push({...archivo})
            }
        },
        eliminar_archivo_array(state, token) {
            console.log(token)
            console.log('jeje')
            let varAux = state.archivos_seleccionados.filter(
                (item) => item.token == token 
            );
            console.log(varAux);
            console.log(varAux[0]);
            var i = state.archivos_seleccionados.indexOf(varAux[0]);
            if (i != -1) {
                state.archivos_seleccionados.splice(i, 1);
            }
        },
        redireccion({ state }, ruta) {

            console.log(state)

            console.log(router.currentRoute.path)
            console.log(ruta)
            if (ruta == '/') {

                dataUser.mutations.DO_LOGOUT(dataUser.state)

            }
            if (router.currentRoute.path != ruta) {
                router.push(ruta)
            }
        },
        cargando(state) {
            state.dialogCargando = true
        },
        error(state, mensaje) {
            console.log(mensaje)
            state.dialogError = true
            state.mensaje = mensaje
            state.load = false
            state.dialogCargando = false
        },
        exitoso(state, mensaje) {
            state.mensaje = mensaje
            state.dialogExitoso = true
            state.load = false
            state.dialogCargando = false
        },
        alerta(state, mensaje) { //Funcion creada por fry
            state.mensaje = mensaje
            state.dialogAlerta = true
        },
        crearToken(state, len) {
            
            let text = ""
            let chars = "abcdefghijklmnopqrstuvwxyz123456789"

            for (let i = 0; i < len; i++) {
                text += chars.charAt(Math.floor(Math.random() * chars.length))
            }
            state.token = state.idUsuario + '_' + text
        },
        
        auth(state, tipo) {

            let dateNow = new Date();
            let dateUTC = new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds())

            // Definimos la diferencia en horas del time zone
            // Para la diferencia horaria de dos horas y media seria 2.5
            let tz = -5;
            // Calculamos los segundos de la zona horaria
            let seconds = (tz * 60 * 60) * 1000;

            // Aplicamos la diferencia horaria añadiendo los segundos al timestamp de la
            // fecha UTC
            var hora = dateUTC.setTime(dateUTC.getTime() + seconds)

            let payload = {
                "iss": "educandote",
                "aud": "educandote.co",
                "iat": hora,
                "exp": hora + 120000,

                "sub": "jwrocket@educandote.co"
            }
            // HS256 secrets are typically 128-bit random strings, for example hex-encoded:
            var jwt = require('jwt-simple');
            //Hacer un condicional para usar el secret
            var secret
            if (tipo == 'parse') {
                secret = '873843748laksldkieurieulsñlddslaksjdaksdjkfjdkfjl38172837'
            } else {
                secret = 'dslaksjdalskdakkskkz13213231asdadasda1o38172837'
            }
            //var secret2 = 'dslaksjdalskdakkskkz13213231asdadasda1o38172837' //Esta es para el upload 
            //secret = '873843748laksldkieurieulsñlddslaksjdaksdjkfjdkfjl38172837' // Para el parse (Ajustar el condicional)

            // encode
            state.header = 'Bearer ' + jwt.encode(payload, secret)
        },
        
    },
    actions: {
        async token_caso({dispatch,rootState}){
            let contador = JSON.stringify('1')
            contador = encodeURIComponent(contador)   
            let limite = JSON.stringify('0') 
            limite = encodeURIComponent(limite)       
            let response = await dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/parse/classes/prueba01TodosLosCasos?count="+contador+'&limit='+limite,
                tipo_header:"parse"
            });
            console.log(response);
            console.log(rootState.dataUser.dataUser)
            let fecha = new Date().toISOString().substr(0, 10);
            let token = rootState.dataUser.dataUser.sector+'-'+fecha+':'+ response.data.count

            return token
        },
        async GENERATE_GUID(){
            let text = ""
            let chars = "abcdefghijklmnopqrstuvwxyz123456789"

            for (let i = 0; i < 8; i++) {
                text += chars.charAt(Math.floor(Math.random() * chars.length))
            }
            return text;
        },

        async GENERATE_TOKEN_CASO({dispatch,rootState}){
            const fecha = new Date().toISOString().substr(0, 10);

            let contador = JSON.stringify('1')
            contador = encodeURIComponent(contador)   
            let limite = JSON.stringify('0') 
            limite = encodeURIComponent(limite) 
            
            let amount_tokens = await dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/parse/classes/prueba01TokensCaso?count="+contador+'&limit='+limite,
                tipo_header:"parse"
            });
            console.log(amount_tokens);

            let response = await dispatch('llamado_post',{
                url: "https://audidoc.educandote.co/parse/classes/prueba01TokensCaso",
                body: {
                    token: await dispatch('GENERATE_GUID'),
                    fecha: fecha,
                    usuario: rootState.dataUser.dataUser.userId,
                    sector: rootState.dataUser.dataUser.sector,
                    consecutivo: amount_tokens.data.count,
                },
                tipo_header:"parse"
            });
            console.log(response);
            console.log(rootState.dataUser.dataUser)
            const token = response.data.objectId+'-'+fecha+':'+ amount_tokens.data.count

            return token
        }
    }
})