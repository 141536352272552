<template>
    <v-card min-width="200" max-width="400" elevation="4" rounded="md">
      <div class="row items-center no-wrap">
        <div class="col">
          <h3 :style="color(node)"  >{{ title }}

           
          </h3>
          <h4>
             {{mensaje}}
          </h4>
          
        </div>
      </div>
      <div v-html="description"/>
      <v-card-actions>
           <v-btn v-if="title!='Inicio' && $store.state.dataUser.dataUser.rango=='admin'" icon color="red" class="pa-1" @click="remove()"><v-icon>mdi-delete</v-icon></v-btn>
           
          <v-spacer></v-spacer>
          <v-menu
            top
            v-if="title!='Fin del proceso' && $store.state.dataUser.dataUser.rango=='admin'  "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-plus</v-icon>
                
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(area, index) in $store.state.admin.areas"
                :key="index"
                @click="agregar_area(area,node)"
              >
                <v-list-item-title>{{ area.nombre }}</v-list-item-title>
              </v-list-item>
            </v-list>
          
          </v-menu>
      </v-card-actions>
     
    </v-card>
</template>
<script>
export default {
  data () {
    return {
      text: 'This is component A',
      mensaje: ""
    }
  },
  methods: {
    agregar_area(area,node){

      let obj = {
        area: area,
        nodo: node
      }
      console.log(node)
      this.$store.commit('addNode',obj)
      console.log(area)
    },
    color(node){
      console.log(node.data)
      let style = 'text-align:center'
      if(this.title!='Fin del proceso'){
        if(node.data.reasignado==true){
           style = 'text-align:center;color:red'
           this.mensaje = "El ciclo en este nodo fue rechazado..."
        }
      }else{
          style = 'text-align:center;color:red'
      }
      console.log(style)
      return style
    
    }
  },
  props: ['remove', 'node', 'title', 'description','add'],

}
</script>