<template>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="cantidad_paginas"
    ></v-pagination>
  </div>
</template>
<script>
import { EventBus } from '../../event/event-bus';
export default {
    props: {
        cantidad_paginas: {
            type: Number,
            required: true
        },
    },
    data(){
        return {
            page: 1
        }
    },
    mounted(){
        EventBus.$on('cambiar_pagina', (payload) => {
            console.log(payload)
            this.page = payload
        })
    },
    watch: {
        page(val){
            this.$emit('buscar',val)
        },
        //Si algun componente requiere una modificaicon de pagina en momento especifico accede a la propiedad
        //Para mutar la pagina
       
    },
}
</script>