<template>
    <v-progress-linear indeterminate color="red darken-3" v-show="load"></v-progress-linear>
</template>
<script>
export default {
    props: {
        load: {
            type: Boolean,
            default: false,
        }
    }
}
</script>