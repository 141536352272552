export default {
    state: {
        
        actualizarUsuariosSector: 0,
        nodo: null,
        agregar_nodo: 0,
        workflow: [],
        guardar_nodos: 0,
        areas: [],
        proceso: 'crear'
    },
    mutations: {
        addNode(state,event){
            state.nodo = event
           
            state.agregar_nodo = Math.random()
        }
    },
    actions: {

    }
}