<template>
    <v-container>
        <v-row>
            <v-col>
            <div
                v-text="mensaje"
                class="header font-weight-light text-center"
            ></div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
            <v-img
                contain
                width="700"
                class="mx-auto"
                :src="require('../../assets/notFound.png')"
            ></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        mensaje: {
            type: String,
            default: "No se encontraron datos"
        }
    },
}
</script>