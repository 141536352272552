<template>
    <v-card>
        <Toolbar 
                  :titulo="titulo" 
                  @activar="$emit('activar')"
                  :color="color"
                  :activar_tool="acciones.activar_tool"
                  :accion="acciones.accion"
                  :icono="acciones.icono" 
                  
        />
        
        <v-progress-linear indeterminate color="purple" v-show="load"></v-progress-linear>
        <v-card-text>
            <slot name="contenido" ></slot>
        </v-card-text>
        
    </v-card>
</template>
<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true,
        },
        load: {
            type: Boolean,
            default: false,
        },
        color:{
            type: String,
        },
        acciones: {
            type: Object,
            default: function() {
                return {
                    activar_tool: true,
                    icono: 'mdi-close',
                    accion: 'cerrar'
                }
                
            }
        }
    },
    data(){
        return {

        }
    }
}
</script>