export default {
    namespaced: true,
    state: {
     
      workflow: [], // A esta variable le voy a asignar el workflow que le corresponde
      areas_seleccionadas: [],
      

    },
    mutations: {
          
    },
    actions: {
        async fecha_limite({dispatch},{fecha_inicial,tipo_documental}){
          //const fechaComoCadena = "2020-03-14"; // día lunes
          let dias_habiles = null
          try {
            let buscar = JSON.stringify({
                nombre: tipo_documental,
              });
    
              buscar = encodeURIComponent(buscar);
            let response = await dispatch('llamado_get',{
              url: "https://audidoc.educandote.co/parse/classes/V1_tiposDocumentales?where="+buscar,
              tipo_header: "parse"
            },{root:true})
            console.log(response)
            dias_habiles = response.data.results[0].fecha_limite
          } catch (error) {
            console.log(error)
          }

          if( (dias_habiles!="") && (dias_habiles!=null) && (typeof dias_habiles != 'undefined') ){
            const dias = [
              'domingo',
              'lunes',
              'martes',
              'miércoles',
              'jueves',
              'viernes',
              'sabado',
            ];
            const numeroDia = new Date(fecha_inicial).getDay();
  
            let ward = true
            //let dias_habiles = 3
            let i = 0, cantidad = 0
            let contador = numeroDia
  
            console.log(dias[contador])
  
            while(ward){
  
              if(dias[contador]!='sabado' && dias[contador]!='domingo'){
                i++
                
              }
  
              if(dias[contador]=='sabado'){
                contador = 0
              }
  
              if(dias_habiles==i){
                ward = false
              }
              contador++
              cantidad++
  
  
            }
  

            fecha_inicial = Date.parse(new Date(fecha_inicial))
            let fecha_final = fecha_inicial + (cantidad*86400000)
  
            let nombre_fecha_final = new Date(fecha_final).toISOString().substr(0, 10)
            
            console.log(fecha_inicial)
            console.log(nombre_fecha_final)
  
            return nombre_fecha_final
          }else{
            return 'El documento no tiene una fecha limite de entrega'
          }
        },
        buscar_sectores({state},{workflow,id}) {
            console.log(workflow);
            console.log(state.areas_seleccionadas)
            let sectores = [];
            for (let index = 0; index < workflow.length; index++) {
              console.log(workflow[index]);
              if (parseInt(workflow[index].parentId) == id && workflow[index].data.reasignado ==false ) {  
                  console.log(workflow[index].data.title);
      
                sectores.push({
                  nombre_sector: workflow[index].data.title,
                  area_id: workflow[index].data.area_id,
                  id_nodo_sector: workflow[index].id,
                });
              }
            }
      
            console.log(sectores);
      
            return sectores;
        },
        agregar_nodos_workflow({state},{areas, parentId}){
            let id 
           

            if(state.workflow.length==0){
                let nodo = {
                    id: 1,
                    parentId: -1,
                    nodeComponent: 'demo-node',
                    data: {
                      text: 'Parent block',
                      title: 'Inicio',
                      description: 'A partir de aqui crea el recorrido del documento por las distintas areas de su entidad. Considera el punto de inicio como el area que recibe ingreso de datos, Ejm: Recepcion ',
                      area_id: -1,
                      reasignado: false,
                    },
                  }
                state.workflow.push(nodo)
            }

            parentId = parseInt(parentId) //El parentId será el nodo al cual se vincularan las nuevas areas. 
                                          //Por consiguiente el parentId debe ser el nodo "actual"
            id = Date.parse(new Date())
            
            console.log(areas)

            for (let index = 0; index < areas.length; index++) {
               
                state.workflow.push(
                    {
                        data: {
                            area_id: areas[index].objectId,
                            description: "El tipo documental ingresara a esta area una vez haya culminado el proceso en el area de: "+areas[index].nombre,
                            text: "El tipo documental ingresara a esta area una vez haya culminado el proceso en el area de: "+areas[index].nombre,
                            title: areas[index].nombre,
                            reasignado: false,
                        },
                        id: id,
                        nodeComponent: "demo-node",
                        parentId: parentId

                    }
                )
                id = (Date.parse(new Date()))+(index+1)
            }

            return state.workflow
        }
    },
  }
  

