export default {
    state: {
        
        inputs_tipo_documental: {
            campos: [],
        },
      
    },
    mutations: {

    },
    actions: {

    }
}