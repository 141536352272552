const axios = require("axios").default;

export default {
  //namespaced: true,
  state: {
    consumers: [],
  },
  mutations: {},
  actions: {
    async llamado_get({ dispatch }, { url, tipo_header }) {
      let header = await dispatch("generar_header", tipo_header);

      //   console.log({ header, url });

      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: header,
          },{ timeout: 10000 })
          .then((response) => {
            // console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },

    async llamado_post({ dispatch }, { url, body, tipo_header }) {
      console.log(url);
      console.log(body);

      let header = await dispatch("generar_header", tipo_header);

      console.log(url, body, header);
      return new Promise((resolve, reject) => {
        axios
          .post(url, body, {
            headers: header,
          })
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    async llamado_delete({ dispatch }, { url, tipo_header }) {
      console.log(url);

      let header = await dispatch("generar_header", tipo_header);

      console.log(url, header);
      return new Promise((resolve, reject) => {
        axios
          .delete(url, {
            headers: header,
          })
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    async llamado_put({ dispatch }, { url, body, tipo_header }) {
      console.log(url);

      let header = await dispatch("generar_header", tipo_header);
      console.log(url, body, header);
      return new Promise((resolve, reject) => {
        axios
          .put(url, body, {
            headers: header,
          })
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    async llamado_patch({ dispatch }, { url, body, tipo_header }) {
      console.log(url);

      let header = await dispatch("generar_header", tipo_header);
      console.log(url, body, header);
      return new Promise((resolve, reject) => {
        axios
          .patch(url, body, {
            headers: header,
          })
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    async generar_header({ dispatch }, tipo_header) {
      let obj_header, header;

      switch (tipo_header) {
        case "parse":
          header = await dispatch("auth", "parse");
          obj_header = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
            "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
            Authorization: header,
          };
          break;
        case "indexador":
          obj_header = {
            "Content-Type": "application/json",
            "X-TYPESENSE-API-KEY":
              "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
          };
          break;
        case "php":
          header = await dispatch("auth", "php");
          obj_header = {
            "Content-Type": "multipart/form-data",
            Authorization: header,
          };
          break;

        default:
          header = await dispatch("auth", "old");
          obj_header = {
            "Access-Control-Allow-Origin": "*",
            Authorization: header,
          };
          break;
      }
      return obj_header;
    },
    auth({ state }, tipo) {
      console.log(state);
      console.log(tipo);
      let dateNow = new Date();
      let dateUTC = new Date(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds()
      );

      // Definimos la diferencia en horas del time zone
      // Para la diferencia horaria de dos horas y media seria 2.5
      let tz = -5;
      // Calculamos los segundos de la zona horaria
      let seconds = tz * 60 * 60 * 1000;

      // Aplicamos la diferencia horaria añadiendo los segundos al timestamp de la
      // fecha UTC
      var hora = dateUTC.setTime(dateUTC.getTime() + seconds);

      let payload = {
        iss: "educandote",
        aud: "educandote.co",
        iat: hora,
        exp: hora + 120000,

        sub: "jwrocket@educandote.co",
      };
      // HS256 secrets are typically 128-bit random strings, for example hex-encoded:
      var jwt = require("jwt-simple");
      var secret;
      if (tipo == "parse") {
        secret = "873843748laksldkieurieulsñlddslaksjdaksdjkfjdkfjl38172837";
      } else {
        secret = "dslaksjdalskdakkskkz13213231asdadasda1o38172837";
      }

      //var secret2 = '873843748laksldkieurieulsñlddslaksjdaksdjkfjdkfjl38172837'

      let header = "Bearer " + jwt.encode(payload, secret);
      return header;
    },
    async crear_endpoint_fin_proceso({ dispatch }) {
      try {
        let fields = [
          {
            name: "fecha_inicio",
            type: "int64",
          },
          {
            name: "fecha_fin",
            type: "int64",
          },
          {
            name: "datos_adjuntos",
            type: "string[]",
          },
          {
            name: "workflow",
            type: "string[]",
          },
          {
            name: "token",
            type: "string",
          },
          {
            name: "solicitante",
            type: "string",
          },
          {
            name: "tipo_documental",
            type: "string",
          },
          {
            name: "fecha_int",
            type: "int64",
          },
        ];

        let body = {
          name: "prueba01almacen_casos_finalizados",
          fields: fields,
          default_sorting_field: "fecha_int",
        };
        console.log(body);

        let response = await dispatch("llamado_post", {
          url: "https://audidoc.educandote.co/types/collections/",
          body: body,
          tipo_header: "indexador",
        });
        console.log(response);
      } catch (error) {
        console.log(error);

        //Aqui habria que crear una promesa segun sea el error para que retorne a la otra funcion
      }
    },
    async crear_endpoint_empalme({ dispatch }) {
      try {
        let fields = [
          {
            name: "carpeta",
            type: "string",
          },
          {
            name: "tipo_archivo",
            type: "string",
          },
          {
            name: "expedientes",
            type: "auto",
          },
          {
            name: "fecha_int",
            type: "int64",
          },
        ];
        let body = {
          name: "pruebafry_archivos_empalme04",
          fields: fields,
          default_sorting_field: "fecha_int",
        };
        console.log(body);

        let response = await dispatch("llamado_post", {
          url: "https://audidoc.educandote.co/types/collections/",
          body: body,
          tipo_header: "indexador",
        });
        console.log(response);
      } catch (error) {
        console.log(error);

        //Aqui habria que crear una promesa segun sea el error para que retorne a la otra funcion
      }
    },
    async crear_endpoint_fuid({ dispatch }) {
      try {
        let fields = [
          {
            name: "codigo_documental",
            type: "string",
          },
          {
            name: "nombre_serie",
            type: "string",
          },
          {
            name: "numero_consecutivo",
            type: "string",
          },
          {
            name: "fecha_inicial",
            type: "string",
          },
          {
            name: "fecha_final",
            type: "string",
          },
          {
            name: "caja_unidad_conservacion",
            type: "string",
          },
          {
            name: "carpeta_unidad_conservacion",
            type: "string",
          },
          {
            name: "tomo_unidad_conservacion",
            type: "string",
          },
          {
            name: "otro_unidad_conservacion",
            type: "string",
          },
          {
            name: "numero_folios",
            type: "string",
          },
          {
            name: "soporte",
            type: "string",
          },
          {
            name: "frecuencia_consulta",
            type: "string",
          },
          {
            name: "observaciones",
            type: "string",
          },
          {
            name: "signatura_topografica_modulo",
            type: "string",
          },
          {
            name: "signatura_topografica_estante",
            type: "string",
          },
          {
            name: "signatura_topografica_entrepaño",
            type: "string",
          },
          {
            name: "signatura_topografica_caja",
            type: "string",
          },
          {
            name: "signatura_topografica_carpeta",
            type: "string",
          },
          {
            name: "fecha_creacion_int",
            type: "int64",
          },
        ];

        let body = {
          name: "inventario_documental02",
          fields: fields,
          default_sorting_field: "fecha_creacion_int",
        };

        console.log(body);

        let response = await dispatch("llamado_post", {
          url: "https://audidoc.educandote.co/types/collections/",
          body: body,
          tipo_header: "indexador",
        });
        console.log(response);
      } catch (error) {
        console.log(error);

        //Aqui habria que crear una promesa segun sea el error para que retorne a la otra funcion
      }
    },
    async crear_endpoint_archivos({ dispatch }, name_endpoint) {
      try {
        let fields_folder = [
          {
            name: "nombre_carpeta",
            type: "string",
            facet: true,
          },
          {
            name: "id_carpeta",
            type: "string",
            facet: true,
          },
          {
            name: "pertenece_a",
            type: "string",
            facet: true, // Padre : Carpeta inicial. ---> de ahí en adelante el ID hace referencia a que pertenece a una subcarpeta
          },
          {
            name: "estado_carpeta",
            type: "string",
            facet: true,
          },
          {
            name: "fecha_creacion_int",
            type: "int64",
          },
          {
            name: "order",
            type: "int64",
          },
          {
            name: "extended_properties",
            type: "string",
          },
        ];
        let body_folder = {
          name: "CARPETAS_" + name_endpoint,
          fields: fields_folder,
          default_sorting_field: "order",
        };

        console.log(body);

        let response_folders = await dispatch("llamado_post", {
          url: "https://docutest.educandote.co/types/collections/",
          body: body_folder,
          tipo_header: "indexador",
        });
        console.log(response_folders);

        //Creacion de imagenes

        //autor
        //estado_imagen
        //fecha_creacion
        //meta_datos
        //nombre (de la imagen)
        //nombre_sector
        //sector
        //tipo
        //token

        let fields_images = [
          {
            name: "id_busqueda",
            type: "string",
            facet: true,
          },
          {
            name: "id_carpeta",
            type: "string",
            facet: true,
          },
          {
            name: "autor",
            type: "string",
            facet: true,
          },
          {
            name: "estado_imagen",
            type: "string",
            facet: true,
          },
          {
            name: "meta_datos",
            type: "string", // Padre : Carpeta inicial. ---> de ahí en adelante el ID hace referencia a que pertenece a una subcarpeta
          },
          {
            name: "nombre",
            type: "string",
          },
          {
            name: "nombre_sector",
            type: "string",
            facet: true,
          },
          {
            name: "sector",
            type: "string",
            facet: true,
          },
          {
            name: "tipo",
            type: "string",
            facet: true,
          },
          {
            name: "token",
            type: "string",
            facet: true,
          },
          {
            name: "fecha_creacion_int",
            type: "int64",
          },
          {
            name: "order",
            type: "int64",
          },
          {
            name: "extended_properties",
            type: "string",
          },
        ];
        let body = {
          name: "IMAGENES_" + name_endpoint,
          fields: fields_images,
          default_sorting_field: "order",
        };

        console.log(body);

        let response_imagenes = await dispatch("llamado_post", {
          url: "https://docutest.educandote.co/types/collections/",
          body: body,
          tipo_header: "indexador",
        });
        console.log(response_imagenes);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    /*
    async crear_endpoint_siett_specific({dispatch}){
        try {
            
           Placa:
           nombre_tipo_tramite
           id_tipo_tramite
           Fecha:
           fecha_documento_int:
           documento_propietario

        } catch (error) {
            console.log(error);
        }
    }
    */
  },
};
