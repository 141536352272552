<template>
    <v-card>
        <v-card-text>
            {{ title }}
        </v-card-text>
      <flowy-drag-handle>
        <v-btn color="grey" icon> <v-icon>mdi-account</v-icon></v-btn>
      </flowy-drag-handle>
    </v-card>
</template>
<script>
export default {

     props: ['remove', 'node', 'title', 'description'],
        data(){
            return {
        text: 'This is component A'
        }
    }
}
</script>